import React, { useEffect, useState } from 'react';
import styles from './OfertaPage.module.css';



const OfertaPage = () =>  {

    return (
        <section id='training' className='training'>
            <div className='container'>
                <div className={`training__title tlt vetrino ` + styles.title}>
                    <h2>ПУБЛІЧНИЙ ДОГОВІР
                        (ДОГОВІР ПУБЛІЧНОЇ ОФЕРТИ)
                        про надання інших освітніх послуг</h2>
                </div>
                <div className={styles.items}>
                    <div className={styles.item}>
                        Документ, положення якого викладені нижче по тексту, є публічною офертою та публічним договором. Згідно із положеннями ст.ст. 633, 641 Цивільного кодексу України умови публічної оферти та публічного договору є однаковими для всіх користувачів (Замовників). Сплата послуг на web-сайті  https://feelyourbody.pp.ua/  (https://feelyourbody.pp.ua/) є акцептом даної оферти, що прирівнюється до укладення договору на умовах викладених нижче по тексту. Дана публічна оферта адресована всім фізичним особам, що бажають скористатися послугою і мають технічну можливість одержання послуги.
                        Приймаючи умови даного договору, покупець підтверджує, що:
                        <p>— на момент купівлі товару йому виповнилось 18 років;</p>
                        <p> — інформація, що надається під час замовлення товарів або послуг, є правильною та актуальною;
                        </p>
                        <p>
                            — надана контактна інформація може бути використана з метою повідомлення про зміни в роботі, акційні пропозиції чи надання іншої інформації, пов’язаної з діяльністю продавця.
                        </p>
                        <p>
                            Фізична особа-підприємець — ФОП Апончук Валерія Юріївна (далі - Виконавець) з однієї сторони, керуючись чинним законодавством України пропонує (публічна оферта) фізичній особі (далі - Замовнику), в подальшому разом — Сторони, а кожен окремо — Сторона, укласти публічний договір про надання послуг (далі - Договір) на наступних умовах:
                        </p>
                    </div>

                    <div className={styles.item}>
                        <h5>1. ТЕРМІНИ ТА ВИЗНАЧЕННЯ</h5>
                        <p>1.1. З метою однозначного трактування тексту цього Договору терміни, наведені нижче, використовуються в наступному значенні:</p>
                        <p>
                            1.1.1. Офіційний вебсайт Виконавця — це міcце розташування вебсторінок, які пов’язані між собою і доступні через головну сторінку вебсайту Виконавця, який розміщений в мережі інтернет за адресою: https://feelyourbody.pp.ua/
                        </p>
                        <p>
                            1.1.2. Публічна оферта — це пропозиція, яка адресована будь-якій фізичній особі щодо надання послуги для укладення цього Договору на встановлених Виконавцем умовах.
                        </p>
                        <p>
                            1.1.3. Акцепт оферти — це повне та беззастережне прийняття оферти шляхом сукупного виконання дій Замовником вказаних в цьому Договорі. Акцептом оферти укладається цей Договір.
                        </p>
                        <p>
                            1.1.4. Інформаційні послуги — це перелік послуг, які можуть надаватися Виконавцем Замовнику. Окремо визначені в переліку інформаційні/додаткові послуги можуть надаватися з використанням дистанційних технологій. Перелік інформаційних/додаткових послуг розміщений на офіційному вебсайті Виконавця.
                        </p>
                        <p>
                            1.1.5. Термін надання інформаційної послуги — це певний проміжок в часі, який визначається тривалістю навчальної програми та умовами цього Договору.
                        </p>
                        <p>
                            1.1.6. У Договорі можуть використовуватися терміни, не визначені в цьому розділі. У такому разі тлумачення терміну здійснюється відповідно до тексту цього Договору.
                        </p>
                    </div>
                    <div className={styles.item}>
                        <h5>2. ЗАГАЛЬНІ ПОЛОЖЕННЯ</h5>
                        <p>2.1. Кожна Сторона гарантує іншій Стороні, що володіє необхідною дієздатністю, а також всіма правами і повноваженнями, необхідними і достатніми для укладення і виконання цього Договору відповідно до його умов.</p>
                        <p>2.2. Цей Договір та Правила навчання розміщені на офіційному веб-сайті Виконавця і є доступними для обов’язкового ознайомлення Замовника до моменту укладення цього Договору.</p>
                    </div>
                    <div className={styles.item}>
                        <h5>3. ПРЕДМЕТ ДОГОВОРУ </h5>
                        <p>3.1. Виконавець зобов’язується на платній основі надати інформаційні/додаткові послуги, а Замовник зобов’язується прийняти та оплатити такі послуги згідно умов цього Договору.</p>
                        <p>3.2. Інформаційні/додаткові послуги надаються Виконавцем в рамках обраної Замовником програми.</p>
                        <p>3.3. Отримувачем інформаційних/додаткових послуг за цим Договором може бути Замовник або особа, яка визначена Замовником.</p>
                        <p>3.4. Формат навчання — онлайн. Відбувається дистанційно на сайті Виконавця. Обов’язкова умова для надання освітніх/додаткових послуг в онлайн форматі є сукупність технічних можливостей (вільний доступ до мережі інтернет та наявність електронного пристрою, який за своїми характеристиками здатний підключатися до мережі інтернет) та умінь (здатність вільно користуватися електронним пристроєм та програмним забезпеченням) Замовника прийняти інформаційні/додаткові послуги в такому форматі.</p>
                    </div>
                    <div className={styles.item}>
                        <h5>4. ПОРЯДОК УКЛАДЕННЯ, ВНЕСЕННЯ ЗМІН ТА РОЗІРВАННЯ ДОГОВОРУ</h5>
                        <p>4.1. Сплата послуг на web-сайті <a href="https://feelyourbody.pp.ua/" target="_blank">https://feelyourbody.pp.ua/</a> є акцептом даної оферти, що прирівнюється до укладення договору на умовах викладених нижче по тексту.</p>
                        <p>4.2. Зміни/доповнення до Договору вносяться Виконавцем та набувають чинності з моменту розміщення на офіційному вебсайті Виконавця нової редакції Договору. Замовник погоджується та приймає зміни/доповнення до Договору безумовно та беззастережно.</p>
                    </div>
                    <div className={styles.item}>
                        <h5>5. ПОРЯДОК РОЗРАХУНКІВ</h5>
                        <p>5.1. Інформаційні/додаткові послуги надаються Замовнику виключно за умови оплати відповідно до затверджених Виконавцем цін.</p>
                        <p>5.2. У випадку розірвання Договору Замовником в односторонньому порядку, сплачені кошти за надання послуг поверненню не підлягають.</p>
                    </div>
                    <div className={styles.item}>
                        <h5>6. ПРАВА ТА ОБОВ’ЯЗКИ ВИКОНАВЦЯ</h5>
                        <p>6.1. Виконавець зобов’язаний:</p>
                        <p>6.1.1. До початку надання інформаційних/додаткових послуг:</p>
                        <p>6.1.1.1. ознайомити Замовника з цим Договором та Правилами надання інформаційних послуг шляхом розміщення на офіційному вебсайті;</p>
                        <p>6.1.1.2. Дотримуватися умов цього Договору.</p>

                        <p>6.2. Виконавець має право:</p>
                        <p>6.2.1. Змінити/відкликати Пропозицію (публічну оферту).</p>
                        <p>6.2.2. Вносити зміни/доповнення в цей Договір.</p>
                        <p>6.2.3. Змінити ціни на інформаційні/додаткові послуги.</p>
                        <p>6.2.4. Залучати до процесу надання інформаціних/додаткових послуг третіх осіб.</p>
                        <p>6.2.5. Встановлювати/змінювати формат надання інформаціних послуг.</p>
                        <p>6.2.6. Вимагати від Замовника дотримання умов цього Договору.</p>
                        <p>6.2.7. Відмовити Замовнику в укладенні цього Договору в разі відсутності технічної можливості надати Замовнику інформаційні/додаткові послуги, які ним запитуються.</p>
                        <p>6.2.8. Достроково розірвати цей Договір у разі систематичного/грубого порушення Замовником умов цього Договору або з інших підстав згідно чинного законодавства України.</p>
                    </div>
                    <div className={styles.item}>
                        <h5>7. ПРАВА ТА ОБОВ’ЯЗКИ ЗАМОВНИКА</h5>
                        <p>7.1. Замовник зобов’язаний:</p>
                        <p>7.1.1. Прийняти та вчасно сплатити інформаційні/додаткові послуги згідно умов цього Договору.</p>
                        <p>7.1.2. Дотримуватися умов цього Договору.</p>

                        <p>7.2. Замовник має право:</p>
                        <p>7.2.1. Отримувати інформаційні/додаткові послуги згідно умов цього Договору.</p>
                        <p>7.2.2. Отримувати від Виконавця інформацію про організацію і виконання інформаційних/додаткових послуг.</p>
                    </div>
                    <div className={styles.item}>
                        <h5>8. ВІДПОВІДАЛЬНІСТЬ СТОРІН ТА ПОРЯДОК РОЗГЛЯДУ СПОРІВ</h5>
                        <p>8.1. За невиконання або неналежне виконання зобов’язань за цим Договором Сторони несуть відповідальність згідно умов цього Договору та чинного законодавства України.</p>
                        <p>8.2. Виконавець несе відповідальність винятково за обсяг наданих інформаційних/додаткових послуг Замовнику згідно умов цього Договору.</p>
                        <p>8.3. Виконавець не несе відповідальності за якість інтернет-зв'язку, збій в роботі програмного забезпечення, перебої в постачанні електроенергії тощо.</p>
                        <p>8.4. У випадку порушення Замовником умов цього Договору Виконавець не несе відповідальності за своїми зобов’язаннями.</p>
                        <p>8.5. Спори, які виникають між Сторонами цього Договору вирішуються шляхом переговорів, а в разі недосягнення згоди підлягають розгляду у встановленому законодавством порядку.</p>
                    </div>
                    <div className={styles.item}>
                        <h5>9. ТЕРМІН ДІЇ ПУБЛІЧНОЇ ОФЕРТИ</h5>
                        <p>9.1. Пропозиція (публічна оферта) набирає чинності з моменту розміщення на офіційному веб-сайті Виконавця та діє до моменту її зміни або відкликання Виконавцем.</p>
                        <p>9.2. Відкликання Виконавцем Пропозиції (публічної оферти) не припиняє дію укладеного Договору між Замовником і Виконавцем.</p>
                    </div>
                    <div className={styles.item}>
                        <h5>10. ІНШІ ПОЛОЖЕННЯ</h5>
                        <p>10.1. Сторони домовилися, що всі виключні майнові права на об'єкти інтелектуальної власності належать Виконавцю.</p>
                        <p>10.2. Відповідно до Закону України «Про захист персональних даних» від 01.06.2010 року № 2297-VI Замовник надає Виконавцеві згоду на обробку та зберігання своїх особистих персональних даних, внесених у Заяву на приєднання до Договору, з метою забезпечення реалізації відносин, що виникли на підставі цього Договору, а також в інших цілях, які не суперечать законодавству України.</p>
                        <p>10.3. Шляхом укладення цього Договору Замовник надає Виконавцеві згоду в порядку ст.ст.307, 308 Цивільного Кодексу України на те, що Замовник може бути знятий Виконавцем на фото-, кіно-, теле-, відеоплівку, електронний пристрій. Отримані фото — та відеоматеріали можуть бути відтворені, публічно показані Виконавцем в рекламних/навчальних цілях.</p>
                    </div>
                    <div className={styles.item}>
                        <p>
                            Всі авторські та суміжні права на отримані фото — та відеоматеріали в будь-якому вигляді та на будь-яких носіях належать Виконавцю та не можуть бути використані без його згоди.
                        </p>

                        <p>
                            10.4. Замовник гарантує, що всі умови цього Договору йому зрозумілі, він погоджується з ними, приймає їх безумовно, беззастережно та в повному обсязі.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default OfertaPage;
