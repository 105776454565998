import React, { useEffect, useState } from 'react';
import VideoTrainingsSection from '../../sections/ProfilePage/VideoTrainingsSection/VideoTrainingsSection';
import TrainingCarouselSection from '../../sections/ProfilePage/TrainingCarouselSection/TrainingCarouselSection';
import { MOCKED_TRAININGS_DATA, MOCKED_TRAININGS_TYPES } from './constants';
import './ProfilePage.css';
import styles from '../../sections/ProfilePage/VideoTrainingsSection/VideoTrainingsSection.module.css';
import Select from '../../../../components/Select/Select';
import FoodSection from '../../sections/ProfilePage/FoodSection/FoodSection';
import { useDispatch, useSelector } from 'react-redux';
import { getCoachingHelper, getFoodHelper } from '../../../../context/content-context/content-context.helper';
import BuyAlertModal from '../../buy-modal/BuyAlertModal';
import { PurchaseProductTypeCoaching, PurchaseProductTypeFood } from '../../../../constants/roles';
import Button from '../../../../components/Button/Button';
import LoginModal from '../../../auth/LoginModal/LoginModal';
import RegisterModal from '../../../auth/RegisterModal/RegisterModal';
import FinishRegistrationModal from '../../../auth/FinishRegistrationModal/FinishRegistrationModal';
import AuthService from '../../../../services/auth-service';
import { useLocation, useNavigate } from 'react-router-dom';

const ProfilePage = () => {
    const [selectedTrainingType, setSelectedTrainingType] = useState();
    const userService = new AuthService();
    useEffect(() => {
        window.scrollTo(0, 0);

        getCoachingHelper(dispatch);
        getFoodHelper(dispatch);
    }, []);

    const handleSelectChange = ({ value, label }) => {
        setSelectedTrainingType(value);
    };

    // Filtered MOCKED_TRAININGS_DATA without selectedTrainingType
    const { [selectedTrainingType]: _, ...filteredTrainingData } =
        MOCKED_TRAININGS_DATA;

    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.user);
    const currentContentState = useSelector(state => state.content);
    const [list, setList] = useState([]);
    const [food, setFoodList] = useState([]);

    useEffect(() => {
        if (currentContentState.coaching || currentContentState.food) {
            setList(currentContentState.coaching ?? []);
            setFoodList(currentContentState.food ?? []);

            // if(currentContentState.coaching && currentContentState.coaching.length > 0) {
            //     const data = formatSelectOptions(currentContentState.coaching, currentContentState.food);
            //     setSelectedTrainingType(data[0].value);
            // }
        }
    }, [currentContentState.coaching, currentContentState.food]);

    const formatSelectOptions = (coaching, food) => {
        let item1 = Object.entries(coaching).map(([itemType, itemData]) => ({
            value: itemData,
            label: itemData.title,
            isPurchased: !!currentUser?.coachingPurchases?.find(x => x?.product?.id === itemData.id),
            isFood: false
        }));

        if (food) {
            const item2 = Object.entries(food).map(([itemType, itemData]) => ({
                value: itemData,
                label: itemData.title,
                isPurchased: !!currentUser?.foodPurchases?.find(x => x?.product?.id === itemData.id),
                isFood: true
            }));

            item1 = item1.concat(item2)
        }

        return item1;
    }

    const [modalIsOpen, setModalIsOpen] = useState(false);
    function onModalCloseHandler() {
        setModalIsOpen(false);
    }

    function onLoginCloseModalHandler() {
        setLoginIsOpen(false);
    }

    function onRegisterCloseModalHandler() {
        setRegisterIsOpen(false);
    }

    function onRegisterRequestedModalHandler() {
        setLoginIsOpen(false);
        setRegisterIsOpen(true);
    }

    function onRegisterRequestedModalHandler() {
        setLoginIsOpen(false);
        setRegisterIsOpen(true);
    }

    function onRegisterFinishedModalHandler(value: boolean) {
        setLoginIsOpen(false);
        setRegisterIsOpen(false);
        setFinishRegistrationIsOpen(value);
    }

    function onRegisterFinishedModalCloseHandler() {
        setFinishRegistrationIsOpen(false);
        navigate("/confirm-number");
    }

    const [loginIsOpen, setLoginIsOpen] = useState(false);
    const [registerIsOpen, setRegisterIsOpen] = useState(false);
    const [finishRegistrationIsOpen, setFinishRegistrationIsOpen] = useState(false);

    const navigate = useNavigate();


    return (
        <div className=''>
            <LoginModal
                onClose={onLoginCloseModalHandler}
                isOpen={loginIsOpen}
                registerRequested={onRegisterRequestedModalHandler} />
            <RegisterModal
                onClose={onRegisterCloseModalHandler}
                isOpen={registerIsOpen}
                setRegistrationFinished={onRegisterFinishedModalHandler}
            />
            <FinishRegistrationModal
                onClose={onRegisterFinishedModalCloseHandler}
                isOpen={finishRegistrationIsOpen}
            />


            <BuyAlertModal
                purchaseProductType={selectedTrainingType?.foodDetails ? PurchaseProductTypeFood :PurchaseProductTypeCoaching}
                onClose={onModalCloseHandler}
                isOpen={modalIsOpen}
                text={"Підвердіть покупку"}
                productId={selectedTrainingType?.id}
            />
            <div className={'container ' + styles.navigation}>
                Головна <span>/</span> {selectedTrainingType?.title}
            </div>

            <div
                className={`videoTrainingsButton ${styles.videoTrainingsButton}`}
            >
                <Select
                    className={styles.videoTrainingsSelectCustom}
                    options={formatSelectOptions(list, food)}
                    selectedOptionValue={selectedTrainingType}
                    onChange={handleSelectChange}
                />
            </div>

            { (selectedTrainingType &&
            (!!currentUser?.foodPurchases?.find(x => x?.product?.id === selectedTrainingType?.id)) &&
                selectedTrainingType?.foodDetails)
                ?   <FoodSection  selectedTrainingType={selectedTrainingType} filteredTrainingData={filteredTrainingData}/>
                :

                selectedTrainingType &&
                !!currentUser?.coachingPurchases?.find(x => x?.product?.id === selectedTrainingType?.id)
                    ?  <VideoTrainingsSection
                        selectedTrainingType={selectedTrainingType}
                        handleSelectChange={handleSelectChange}
                    />
                : selectedTrainingType &&
                    !(!!currentUser?.coachingPurchases?.find(x => x?.product?.id === selectedTrainingType?.id) ||
                        !!currentUser?.foodPurchases?.find(x => x?.product?.id === selectedTrainingType?.id)
                    )
                        ? <div className={styles.mainText}>
                            <p>Для перегляду цього тренування, потрібного його придбати</p>
                            <Button
                                className={styles.btn}
                                aria-expanded={true}
                                aria-controls={`coach-modal`}
                                onClick={() => userService.isAuthorized() ? setModalIsOpen(true) : setLoginIsOpen(true)}
                            >
                                <p>Купити</p>
                            </Button>
                        </div>
                        : <div className={styles.mainText}>
                        <p>Оберіть потрібне тренування зверху!</p>
                    </div>
            }
            <TrainingCarouselSection
                filteredTrainingData={filteredTrainingData}
            />
        </div>
    );
};

export default ProfilePage;
