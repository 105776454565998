import React, { useState } from 'react';

import './Footer.css';

import footerPhoto1 from '../../img/photo/photo4.jpg';
import footerPhoto2 from '../../img/photo/photo5.jpg';
import logo from '../../img/darklogo.svg';
import instagram from '../../img/instagram.svg';
import FinishRegistrationModal from '../../modules/auth/FinishRegistrationModal/FinishRegistrationModal';
import ContactsModal from './ContactsModal';
import PaymentModal from './PaymentModal';

const Footer = () => {

    const [contactsModalIsOpen, setContactsModalIsOpen] = useState(false);
    const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false);


    function navigateOnHomePage(elementText: string) {
        if (elementText === 'Тренування') {
            const trainingElement = document.getElementById('training');
            if (trainingElement) {
                const elementPosition =
                    trainingElement.getBoundingClientRect().top;
                window.scrollBy({
                    top: elementPosition - 110,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        } else if (elementText === 'Тренери') {
            const trainersElement = document.getElementById('trainers');
            if (trainersElement) {
                const elementPosition =
                    trainersElement.getBoundingClientRect().top;
                window.scrollBy({
                    top: elementPosition - 40,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        } else if (elementText === 'FAQ') {
            const FAQElement = document.getElementById('FAQ');
            if (FAQElement) {
                const elementPosition = FAQElement.getBoundingClientRect().top;
                window.scrollBy({
                    top: elementPosition - 40,
                    behavior: 'smooth',
                });
            }
        }
    }

    return <>
        <ContactsModal
            onClose={() => setContactsModalIsOpen(false)}
            isOpen={contactsModalIsOpen}
        />

        <PaymentModal
            onClose={() => setPaymentModalIsOpen(false)}
            isOpen={paymentModalIsOpen}
        />

        <footer className='footer'>
        <div className='container'>
            <div className='footer__block-main'>
                <div className='footer__logo footer-main'>
                    <a href='/'>
                        <img src={logo} alt='Logo' />
                    </a>
                </div>
                {/* TODO: It makes sense to make a separate component  */}
                <div className='footer__nav footer-main'>
                    <nav className='footer__menu'>
                        <ul className='footer__menu_items'>
                            <li>
                                <a onClick={() => navigateOnHomePage('Тренування')}>Тренування</a>
                            </li>
                            <li>
                                <a onClick={() => navigateOnHomePage('Тренери')}>Тренери</a>
                            </li>
                            <li>
                                <a onClick={() => navigateOnHomePage('FAQ')}>FAQ</a>
                            </li>
                            <li>
                                <a href='/profile'>Мої тренування</a>
                            </li>
                        </ul>
                        <ul className='footer__menu_items'>
                            <li>
                                <a onClick={() => setContactsModalIsOpen(true)}>Контакти</a>
                            </li>
                            <li>
                                <a onClick={() => setPaymentModalIsOpen(true)}>Оплата</a>
                            </li>
                            <li>
                                <a href='/proposition-info'>Договір публічної оферти</a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className='footer__instagram footer-main'>
                    <div className='footer__inst_Lyda'>
                        <a href='https://www.instagram.com/lyudastrilyk/'>
                            <img src={instagram} alt='Instagram' />
                            <div className='footer__inst_name vetrino'>
                                Люда
                            </div>
                        </a>
                    </div>
                    <div className='footer__inst_Lera'>
                        <a href='https://www.instagram.com/lerysik27/'>
                            <img src={instagram} alt='Instagram' />
                            <div className='footer__inst_name vetrino'>
                                Валерія
                            </div>
                        </a>
                    </div>
                </div>
                <div className='footer__photo footer-main'>
                    <img className='footer__photo_passive' src={footerPhoto1} />
                    <img className='footer__photo_active' src={footerPhoto2} />
                </div>
            </div>
        </div>
    </footer>
    </>
};

export default Footer;
